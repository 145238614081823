/**
 * Custom axios instance
 * Interceptors will be configured by the user of this instance
 */

import type { AxiosRequestConfig, AxiosError as BaseAxiosError } from 'axios';
import axios, { isAxiosError } from 'axios';
import { baseUrl } from './baseUrl';

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-PROTECTION': '1',
  },
  withCredentials: true,
});

// Wrapper over axiosInstance
export const customAxios = <T>(
  config: AxiosRequestConfig
  // options?: AxiosRequestConfig //
): Promise<T> => {
  return (
    axiosInstance<T>({
      ...config,
      // ...options,
    })
      // axios wraps response into an object with field named data. Destructure it and return response directly
      .then(({ data }) => data)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Only specifying the first type (error)
export type AxiosError<D = any> = BaseAxiosError<{ message: string }, D>;

export const getErrorMessage = (
  e: unknown,
  options: {
    fallbackErrorMessage: string;
    /**
     * Whether to display the `fallbackErrorMessage` on server errors (500), as typically this is 'Internal Server Error'. Defaults to true.
     */
    fallbackErrorMessageOnServerError?: boolean;
  } = {
    fallbackErrorMessage: 'An unexpected error has occurred. Please try again.',
    fallbackErrorMessageOnServerError: true,
  }
): string => {
  const { fallbackErrorMessage, fallbackErrorMessageOnServerError } = options;
  let errorMessage: string | undefined;
  // Error is either a backend error (AxiosError), or a custom thrown error
  if (isAxiosError(e)) {
    const error = e as AxiosError;
    errorMessage =
      fallbackErrorMessageOnServerError && error?.status && error.status >= 500
        ? fallbackErrorMessage
        : error.response?.data?.message;
  } else {
    // User thrown error
    errorMessage = (e as Error)?.message;
  }
  return errorMessage || fallbackErrorMessage;
};

export { isAxiosError } from 'axios';
