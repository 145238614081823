import { createSlice, type PayloadAction, createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

export interface SignUpState {
  username: string;
  password: string;
  preferredName: string;
  termsAndConditionsChecked: boolean;
  emailAddress: string;
  marketingAndOffersStatus: boolean;
  signupId: string;
  customerNumber: string;
  mobileNumber: string;
}

export const initialSignUpState: SignUpState = {
  username: '',
  password: '',
  preferredName: '',
  termsAndConditionsChecked: false,
  emailAddress: '',
  marketingAndOffersStatus: true,
  signupId: '',
  customerNumber: '',
  mobileNumber: '',
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState: initialSignUpState,
  reducers: {
    setUsername: (state, action: PayloadAction<SignUpState['username']>) => {
      state.username = action.payload;
    },
    setPassword: (state, action: PayloadAction<SignUpState['password']>) => {
      state.password = action.payload;
    },
    setPreferredName: (state, action: PayloadAction<SignUpState['preferredName']>) => {
      state.preferredName = action.payload;
    },
    setTermsAndConditionsChecked: (state, action: PayloadAction<SignUpState['termsAndConditionsChecked']>) => {
      state.termsAndConditionsChecked = action.payload;
    },
    setEmailAddress: (state, action: PayloadAction<SignUpState['emailAddress']>) => {
      state.emailAddress = action.payload;
    },
    setMarketingAndOffersStatus: (state, action: PayloadAction<SignUpState['marketingAndOffersStatus']>) => {
      state.marketingAndOffersStatus = action.payload;
    },
    setSignupId: (state, action: PayloadAction<SignUpState['signupId']>) => {
      state.signupId = action.payload;
    },
    setCustomerNumber: (state, action: PayloadAction<SignUpState['customerNumber']>) => {
      state.customerNumber = action.payload;
    },
    setMobileNumber: (state, action: PayloadAction<SignUpState['mobileNumber']>) => {
      state.mobileNumber = action.payload;
    },
    resetSignUp: (state) => {
      state.username = initialSignUpState.username;
      state.password = initialSignUpState.password;
      state.preferredName = initialSignUpState.preferredName;
      state.termsAndConditionsChecked = initialSignUpState.termsAndConditionsChecked;
      state.emailAddress = initialSignUpState.emailAddress;
      state.marketingAndOffersStatus = initialSignUpState.marketingAndOffersStatus;
      state.signupId = initialSignUpState.signupId;
      state.mobileNumber = initialSignUpState.mobileNumber;
    },
  },
});

export const selectAuthDetails = createSelector(
  (state: RootState) => state.signup,
  (signup) => ({
    signupId: signup.signupId,
    nickname: signup.preferredName,
    password: signup.password,
    termsAndConditionsChecked: signup.termsAndConditionsChecked,
    emailAddress: signup.emailAddress,
    mobileNumber: signup.mobileNumber,
    preferredName: signup.preferredName,
    customerNumber: signup.customerNumber,
    marketingAndOffersStatus: signup.marketingAndOffersStatus,
  })
);

export const {
  setUsername,
  setPassword,
  setPreferredName,
  setTermsAndConditionsChecked,
  setEmailAddress,
  setMarketingAndOffersStatus,
  setSignupId,
  setCustomerNumber,
  setMobileNumber,
  resetSignUp,
} = signupSlice.actions;

export default signupSlice.reducer;
