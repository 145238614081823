'use client';

import type { MutexInterface } from 'async-mutex';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useFlag from 'app/hooks/useFlag';
import { axiosInstanceMutex, refreshTokenMutex } from 'app/services/mutex';
import { setAuthState } from 'packages/app/features/auth/authSlice';
import { useAppSelector } from 'packages/app/store';
import { appClient } from 'packages/app/utils/appClient';
import { refreshToken } from './refreshToken';

/**
 * Wrapper around internal refreshToken function to be used in components.
 * Abstracts away the featureFlag call so components don't need to explicitly
 * retrieve the flag themselves.
 *
 * Note: Not to be confused with the mutation useRefreshToken defined in app/users.
 * @returns
 */
export const useRefreshToken = () => {
  const auth0Enabled = useFlag('enableV2Auth0');
  const refreshTokenValue = useAppSelector((state) => state.auth.refreshToken);

  const dispatch = useDispatch();
  const releaseRef = useRef<MutexInterface.Releaser>(() => {
    // Do nothing
  });

  const refresh = useCallback(
    async ({ entityId }: { entityId?: string } = {}) => {
      // Need to wait for other refresh token requests to finish, as they would invalidate the current refresh token, making this request fail.
      await refreshTokenMutex.waitForUnlock();
      const release = await refreshTokenMutex.acquire();
      releaseRef.current = release;
      // Need to wait for any refresh token requests made from response interceptor to finish as well.
      await axiosInstanceMutex.waitForUnlock();
      try {
        // Interceptors will update tokenInfo and handle errors.
        const resp = await refreshToken({
          entityId,
          auth0Enabled,
          refreshToken: refreshTokenValue && appClient === 'MOBILE' ? refreshTokenValue : undefined,
        });

        // Mobile app response will have the tokens in the response body.
        if (typeof resp === 'object' && 'AccessToken' in resp) {
          dispatch(
            setAuthState({
              accessToken: resp.AccessToken,
              idToken: resp.IdToken,
              refreshToken: resp.RefreshToken,
            })
          );
        }
      } finally {
        release();
      }
    },
    [auth0Enabled, dispatch, refreshTokenValue]
  );

  const releaseLock = useCallback(() => {
    releaseRef.current();
  }, []);

  return useMemo(() => [refresh, releaseLock] as const, [refresh, releaseLock]);
};
