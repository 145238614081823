import type { ComponentType } from 'react';
import { TrackScreenViewWrapper } from './TrackScreenViewWrapper';

export const EVENT_NAME = 'web.webPageDetails.pageviewComplete';

export interface WithTrackScreenViewProps {
  pageName: string;
}

export function withTrackScreenView<Props extends object>(
  Component: ComponentType<Props>,
  args: WithTrackScreenViewProps
) {
  const displayName = Component.displayName || Component.name || 'Component';

  const ComponentWithAnalytics = (props: Props) => {
    return (
      <TrackScreenViewWrapper eventName={EVENT_NAME} pageName={args.pageName}>
        <Component {...props} />
      </TrackScreenViewWrapper>
    );
  };

  ComponentWithAnalytics.displayName = `withTrackScreenView(${displayName})`;

  return ComponentWithAnalytics;
}
