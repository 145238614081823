'use client';

import { createContext, useContext, type ReactNode } from 'react';

export type AnalyticsContextValue =
  | 'Other'
  | 'Home'
  | 'Onboarding'
  | 'Signup'
  | 'UnsecuredLoanOrigination'
  | 'SecuredVehicleLoanOrigination'
  | 'Settings'
  | 'Cards'
  | 'Accounts'
  | 'Payments'
  | 'LoanLifecycle';

const AnalyticsContext = createContext<AnalyticsContextValue>('Other');

const { Provider: InnerAnalyticsContextProvider } = AnalyticsContext;

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);

  return context;
};

export const AnalyticsContextProvider = ({
  children,
  context,
}: {
  context: AnalyticsContextValue;
  children: ReactNode;
}) => {
  return <InnerAnalyticsContextProvider value={context}>{children}</InnerAnalyticsContextProvider>;
};
