import { baseUrl } from '@cxnpl/api/baseUrl';
import axios from 'axios';
import { appClient } from 'app/utils/appClient';

export async function logoutNoInterceptor(auth0Enabled = false): Promise<void> {
  const logoutUrl = auth0Enabled ? '/v2/session/revokeSession' : '/login/revokeSession';
  const payload = auth0Enabled ? { appClient, redirectUri: `${window.location.href}` } : { appClient };
  // not using axiosInstance means it will not hit interceptors
  await axios({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-PROTECTION': '1',
    },
    withCredentials: true,
    url: logoutUrl,
    method: 'patch',
    data: payload,
  });
}
