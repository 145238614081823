'use client';

import { useContext } from 'react';
import type { ThemeSwitcherContextValue } from './ThemeSwitcherContext';
import { ThemeSwitcherContext } from './ThemeSwitcherContext';

export const useThemeSwitcher = (): ThemeSwitcherContextValue => {
  const ctx = useContext(ThemeSwitcherContext);
  if (typeof ctx === 'undefined') {
    throw new Error('The useThemeSwitcher hook must be used within a ThemeSwitcherProvider');
  }
  return ctx;
};
