'use client';

import { tamaguiConfig, TamaguiProvider, type TamaguiProviderProps } from '@cxnpl/ui';
import type { ReactNode } from 'react';
import { ThemeSwitcherProvider } from '../ThemeSwitcher';

export interface UIProviderProps extends Partial<TamaguiProviderProps> {
  children: ReactNode;
}

export function UIProvider({ config, defaultTheme, children, ...props }: UIProviderProps) {
  return (
    <TamaguiProvider
      config={config ?? tamaguiConfig}
      defaultTheme={defaultTheme ?? 'dark'} // TODO system / local user setting
      {...props}
    >
      <ThemeSwitcherProvider>{children}</ThemeSwitcherProvider>
    </TamaguiProvider>
  );
}
