/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetPermissions200,
  GetPermissionsBody,
  Login200,
  LoginBody,
  Refresh200,
  RefreshBody,
  RegisterDeviceBody,
  RevokeSession200,
  RevokeSessionBody,
  SendForgotPwdLinkBody,
  WebLoginCallbackParams,
  WebLoginParams,
} from '../api.schemas';
import { customAxios } from '../axios';

export const registerDevice = (registerDeviceBody: RegisterDeviceBody) => {
  return customAxios<void>({
    url: `/v2/session/device/register`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: registerDeviceBody,
  });
};

export const getRegisterDeviceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerDevice>>,
    TError,
    { data: RegisterDeviceBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof registerDevice>>, TError, { data: RegisterDeviceBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerDevice>>, { data: RegisterDeviceBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return registerDevice(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof registerDevice>>>;
export type RegisterDeviceMutationBody = RegisterDeviceBody;
export type RegisterDeviceMutationError = unknown;

export const useRegisterDevice = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerDevice>>,
    TError,
    { data: RegisterDeviceBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof registerDevice>>, TError, { data: RegisterDeviceBody }, TContext> => {
  const mutationOptions = getRegisterDeviceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPermissions = (getPermissionsBody: GetPermissionsBody) => {
  return customAxios<GetPermissions200>({
    url: `/v2/session/permissions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: getPermissionsBody,
  });
};

export const getGetPermissionsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    { data: GetPermissionsBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof getPermissions>>, TError, { data: GetPermissionsBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getPermissions>>, { data: GetPermissionsBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return getPermissions(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetPermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof getPermissions>>>;
export type GetPermissionsMutationBody = GetPermissionsBody;
export type GetPermissionsMutationError = unknown;

export const useGetPermissions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    { data: GetPermissionsBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof getPermissions>>, TError, { data: GetPermissionsBody }, TContext> => {
  const mutationOptions = getGetPermissionsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const login = (loginBody: LoginBody) => {
  return customAxios<Login200>({
    url: `/v2/session/login`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: loginBody,
  });
};

export const getLoginMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, { data: LoginBody }> = (props) => {
    const { data } = props ?? {};

    return login(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>;
export type LoginMutationBody = LoginBody;
export type LoginMutationError = unknown;

export const useLogin = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof login>>, TError, { data: LoginBody }, TContext> => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const refresh = (refreshBody: RefreshBody) => {
  return customAxios<Refresh200>({
    url: `/v2/session/refresh`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: refreshBody,
  });
};

export const getRefreshMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refresh>>, { data: RefreshBody }> = (props) => {
    const { data } = props ?? {};

    return refresh(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshMutationResult = NonNullable<Awaited<ReturnType<typeof refresh>>>;
export type RefreshMutationBody = RefreshBody;
export type RefreshMutationError = unknown;

export const useRefresh = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof refresh>>, TError, { data: RefreshBody }, TContext> => {
  const mutationOptions = getRefreshMutationOptions(options);

  return useMutation(mutationOptions);
};
export const revokeSession = (revokeSessionBody: RevokeSessionBody) => {
  return customAxios<RevokeSession200>({
    url: `/v2/session/revokeSession`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: revokeSessionBody,
  });
};

export const getRevokeSessionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeSession>>,
    TError,
    { data: RevokeSessionBody },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof revokeSession>>, TError, { data: RevokeSessionBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeSession>>, { data: RevokeSessionBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return revokeSession(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeSessionMutationResult = NonNullable<Awaited<ReturnType<typeof revokeSession>>>;
export type RevokeSessionMutationBody = RevokeSessionBody;
export type RevokeSessionMutationError = unknown;

export const useRevokeSession = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeSession>>,
    TError,
    { data: RevokeSessionBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof revokeSession>>, TError, { data: RevokeSessionBody }, TContext> => {
  const mutationOptions = getRevokeSessionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const webLogin = (params: WebLoginParams, signal?: AbortSignal) => {
  return customAxios<void>({ url: `/v2/session/web-login`, method: 'GET', params, signal });
};

export const getWebLoginQueryKey = (params: WebLoginParams) => {
  return [`/v2/session/web-login`, ...(params ? [params] : [])] as const;
};

export const getWebLoginQueryOptions = <TData = Awaited<ReturnType<typeof webLogin>>, TError = unknown>(
  params: WebLoginParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWebLoginQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webLogin>>> = ({ signal }) => webLogin(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof webLogin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WebLoginQueryResult = NonNullable<Awaited<ReturnType<typeof webLogin>>>;
export type WebLoginQueryError = unknown;

export const useWebLogin = <TData = Awaited<ReturnType<typeof webLogin>>, TError = unknown>(
  params: WebLoginParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLogin>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWebLoginQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const webLoginCallback = (params: WebLoginCallbackParams, signal?: AbortSignal) => {
  return customAxios<void>({ url: `/v2/session/web-login/callback`, method: 'GET', params, signal });
};

export const getWebLoginCallbackQueryKey = (params: WebLoginCallbackParams) => {
  return [`/v2/session/web-login/callback`, ...(params ? [params] : [])] as const;
};

export const getWebLoginCallbackQueryOptions = <TData = Awaited<ReturnType<typeof webLoginCallback>>, TError = unknown>(
  params: WebLoginCallbackParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWebLoginCallbackQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof webLoginCallback>>> = ({ signal }) =>
    webLoginCallback(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof webLoginCallback>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type WebLoginCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof webLoginCallback>>>;
export type WebLoginCallbackQueryError = unknown;

export const useWebLoginCallback = <TData = Awaited<ReturnType<typeof webLoginCallback>>, TError = unknown>(
  params: WebLoginCallbackParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof webLoginCallback>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getWebLoginCallbackQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Called by Auth0 passing the Magic Link so we can generate the email here to reset the password flow requested by the user.
 */
export const sendForgotPwdLink = (sendForgotPwdLinkBody: SendForgotPwdLinkBody) => {
  return customAxios<void>({
    url: `/v2/session/forgot-passwd-link`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: sendForgotPwdLinkBody,
  });
};

export const getSendForgotPwdLinkMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendForgotPwdLink>>,
    TError,
    { data: SendForgotPwdLinkBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendForgotPwdLink>>,
  TError,
  { data: SendForgotPwdLinkBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendForgotPwdLink>>, { data: SendForgotPwdLinkBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return sendForgotPwdLink(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendForgotPwdLinkMutationResult = NonNullable<Awaited<ReturnType<typeof sendForgotPwdLink>>>;
export type SendForgotPwdLinkMutationBody = SendForgotPwdLinkBody;
export type SendForgotPwdLinkMutationError = unknown;

export const useSendForgotPwdLink = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendForgotPwdLink>>,
    TError,
    { data: SendForgotPwdLinkBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendForgotPwdLink>>,
  TError,
  { data: SendForgotPwdLinkBody },
  TContext
> => {
  const mutationOptions = getSendForgotPwdLinkMutationOptions(options);

  return useMutation(mutationOptions);
};
