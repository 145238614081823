'use client';

import { createContext } from 'react';
import type { ThemeName } from '@cxnpl/ui';

export interface ThemeSwitcherContextValue {
  theme: ThemeName;
  setTheme: (theme: ThemeName) => void;
  toggleTheme: () => void;
}

export const ThemeSwitcherContext = createContext<ThemeSwitcherContextValue | undefined>(undefined);
