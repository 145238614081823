'use client';
import { useEffect, type ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { UnexpectedError } from '../features/errors';
import useLogError from '../hooks/useLogError';
import { generateHash } from '../utils/generateHash';

export interface ErrorBoundaryProviderProps {
  children: ReactNode;
}

function UnexpectedErrorWithLog({ error }: { error: Error }) {
  const logError = useLogError();
  const errorTrackerId = generateHash();

  useEffect(() => {
    void logError(error, errorTrackerId);
  }, [error, logError, errorTrackerId]);

  return <UnexpectedError errorTrackerId={errorTrackerId} />;
}

// ErrorBoundaryProvider does not redefine FallbackRender during render
export function ErrorBoundaryProvider({ children }: ErrorBoundaryProviderProps) {
  return <ErrorBoundary FallbackComponent={UnexpectedErrorWithLog}>{children}</ErrorBoundary>;
}
