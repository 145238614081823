/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  CreateLoginIssueCaseBody,
  EndChatBody,
  GetChatsResponse,
  GetTranscriptResponse,
  IntercomHmac,
  RecordFaqCategoryQuestionStringAnswerStringArray,
  RecordMessageReceiptBody,
  RefreshChatBody,
  RefreshChatResponse,
  SendMessageBody,
  SendTypingEventBody,
  StartChatBody,
  StartChatResponse,
} from '../api.schemas';
import { customAxios } from '../axios';

/**
 * Fetches the user's chats
 */
export const getChats = (signal?: AbortSignal) => {
  return customAxios<GetChatsResponse[]>({ url: `/help/chat`, method: 'GET', signal });
};

export const getGetChatsQueryKey = () => {
  return [`/help/chat`] as const;
};

export const getGetChatsQueryOptions = <TData = Awaited<ReturnType<typeof getChats>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getChats>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChatsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChats>>> = ({ signal }) => getChats(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getChats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetChatsQueryResult = NonNullable<Awaited<ReturnType<typeof getChats>>>;
export type GetChatsQueryError = unknown;

export const useGetChats = <TData = Awaited<ReturnType<typeof getChats>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getChats>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetChatsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Starts a new chat
 */
export const startChat = (startChatBody: StartChatBody) => {
  return customAxios<StartChatResponse>({
    url: `/help/chat`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: startChatBody,
  });
};

export const getStartChatMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof startChat>>, TError, { data: StartChatBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof startChat>>, TError, { data: StartChatBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof startChat>>, { data: StartChatBody }> = (props) => {
    const { data } = props ?? {};

    return startChat(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartChatMutationResult = NonNullable<Awaited<ReturnType<typeof startChat>>>;
export type StartChatMutationBody = StartChatBody;
export type StartChatMutationError = unknown;

export const useStartChat = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof startChat>>, TError, { data: StartChatBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof startChat>>, TError, { data: StartChatBody }, TContext> => {
  const mutationOptions = getStartChatMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Ends a chat session
 */
export const endChat = (endChatBody: EndChatBody) => {
  return customAxios<void>({
    url: `/help/chat`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: endChatBody,
  });
};

export const getEndChatMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof endChat>>, TError, { data: EndChatBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof endChat>>, TError, { data: EndChatBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof endChat>>, { data: EndChatBody }> = (props) => {
    const { data } = props ?? {};

    return endChat(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type EndChatMutationResult = NonNullable<Awaited<ReturnType<typeof endChat>>>;
export type EndChatMutationBody = EndChatBody;
export type EndChatMutationError = unknown;

export const useEndChat = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof endChat>>, TError, { data: EndChatBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof endChat>>, TError, { data: EndChatBody }, TContext> => {
  const mutationOptions = getEndChatMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the transcript for a specific chat
 */
export const getChatTranscript = (contactId: string, signal?: AbortSignal) => {
  return customAxios<GetTranscriptResponse>({ url: `/help/chat/transcript/${contactId}`, method: 'GET', signal });
};

export const getGetChatTranscriptQueryKey = (contactId: string) => {
  return [`/help/chat/transcript/${contactId}`] as const;
};

export const getGetChatTranscriptQueryOptions = <
  TData = Awaited<ReturnType<typeof getChatTranscript>>,
  TError = unknown,
>(
  contactId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatTranscript>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChatTranscriptQueryKey(contactId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChatTranscript>>> = ({ signal }) =>
    getChatTranscript(contactId, signal);

  return { queryKey, queryFn, enabled: !!contactId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getChatTranscript>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetChatTranscriptQueryResult = NonNullable<Awaited<ReturnType<typeof getChatTranscript>>>;
export type GetChatTranscriptQueryError = unknown;

export const useGetChatTranscript = <TData = Awaited<ReturnType<typeof getChatTranscript>>, TError = unknown>(
  contactId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getChatTranscript>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetChatTranscriptQueryOptions(contactId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Records the receipt of a message received
 */
export const recordMessageReceipt = (recordMessageReceiptBody: RecordMessageReceiptBody) => {
  return customAxios<void>({
    url: `/help/chat/receipt`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: recordMessageReceiptBody,
  });
};

export const getRecordMessageReceiptMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recordMessageReceipt>>,
    TError,
    { data: RecordMessageReceiptBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recordMessageReceipt>>,
  TError,
  { data: RecordMessageReceiptBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recordMessageReceipt>>,
    { data: RecordMessageReceiptBody }
  > = (props) => {
    const { data } = props ?? {};

    return recordMessageReceipt(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecordMessageReceiptMutationResult = NonNullable<Awaited<ReturnType<typeof recordMessageReceipt>>>;
export type RecordMessageReceiptMutationBody = RecordMessageReceiptBody;
export type RecordMessageReceiptMutationError = unknown;

export const useRecordMessageReceipt = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recordMessageReceipt>>,
    TError,
    { data: RecordMessageReceiptBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recordMessageReceipt>>,
  TError,
  { data: RecordMessageReceiptBody },
  TContext
> => {
  const mutationOptions = getRecordMessageReceiptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Refreshes a chat session
 */
export const refreshChat = (refreshChatBody: RefreshChatBody) => {
  return customAxios<RefreshChatResponse>({
    url: `/help/chat/refresh`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: refreshChatBody,
  });
};

export const getRefreshChatMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refreshChat>>, TError, { data: RefreshChatBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof refreshChat>>, TError, { data: RefreshChatBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshChat>>, { data: RefreshChatBody }> = (props) => {
    const { data } = props ?? {};

    return refreshChat(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshChatMutationResult = NonNullable<Awaited<ReturnType<typeof refreshChat>>>;
export type RefreshChatMutationBody = RefreshChatBody;
export type RefreshChatMutationError = unknown;

export const useRefreshChat = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refreshChat>>, TError, { data: RefreshChatBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof refreshChat>>, TError, { data: RefreshChatBody }, TContext> => {
  const mutationOptions = getRefreshChatMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sends a message to a chat
 */
export const sendMessage = (sendMessageBody: SendMessageBody) => {
  return customAxios<void>({
    url: `/help/chat/message`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: sendMessageBody,
  });
};

export const getSendMessageMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sendMessage>>, TError, { data: SendMessageBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof sendMessage>>, TError, { data: SendMessageBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendMessage>>, { data: SendMessageBody }> = (props) => {
    const { data } = props ?? {};

    return sendMessage(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendMessageMutationResult = NonNullable<Awaited<ReturnType<typeof sendMessage>>>;
export type SendMessageMutationBody = SendMessageBody;
export type SendMessageMutationError = unknown;

export const useSendMessage = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sendMessage>>, TError, { data: SendMessageBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof sendMessage>>, TError, { data: SendMessageBody }, TContext> => {
  const mutationOptions = getSendMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sends a typing event to a chat
 */
export const sendTypingEvent = (sendTypingEventBody: SendTypingEventBody) => {
  return customAxios<void>({
    url: `/help/chat/typing`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: sendTypingEventBody,
  });
};

export const getSendTypingEventMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendTypingEvent>>,
    TError,
    { data: SendTypingEventBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendTypingEvent>>,
  TError,
  { data: SendTypingEventBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendTypingEvent>>, { data: SendTypingEventBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return sendTypingEvent(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendTypingEventMutationResult = NonNullable<Awaited<ReturnType<typeof sendTypingEvent>>>;
export type SendTypingEventMutationBody = SendTypingEventBody;
export type SendTypingEventMutationError = unknown;

export const useSendTypingEvent = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendTypingEvent>>,
    TError,
    { data: SendTypingEventBody },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof sendTypingEvent>>, TError, { data: SendTypingEventBody }, TContext> => {
  const mutationOptions = getSendTypingEventMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the FAQs
 */
export const getFaqs = (signal?: AbortSignal) => {
  return customAxios<RecordFaqCategoryQuestionStringAnswerStringArray>({ url: `/help/faqs`, method: 'GET', signal });
};

export const getGetFaqsQueryKey = () => {
  return [`/help/faqs`] as const;
};

export const getGetFaqsQueryOptions = <TData = Awaited<ReturnType<typeof getFaqs>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFaqsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFaqs>>> = ({ signal }) => getFaqs(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFaqs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFaqsQueryResult = NonNullable<Awaited<ReturnType<typeof getFaqs>>>;
export type GetFaqsQueryError = unknown;

export const useGetFaqs = <TData = Awaited<ReturnType<typeof getFaqs>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFaqs>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFaqsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the Intercom HMACs for the user
 */
export const getIntercomHmac = (signal?: AbortSignal) => {
  return customAxios<IntercomHmac>({ url: `/help/intercomHmac`, method: 'GET', signal });
};

export const getGetIntercomHmacQueryKey = () => {
  return [`/help/intercomHmac`] as const;
};

export const getGetIntercomHmacQueryOptions = <
  TData = Awaited<ReturnType<typeof getIntercomHmac>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIntercomHmacQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIntercomHmac>>> = ({ signal }) => getIntercomHmac(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIntercomHmac>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIntercomHmacQueryResult = NonNullable<Awaited<ReturnType<typeof getIntercomHmac>>>;
export type GetIntercomHmacQueryError = unknown;

export const useGetIntercomHmac = <TData = Awaited<ReturnType<typeof getIntercomHmac>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getIntercomHmac>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIntercomHmacQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a task in Amazon Connect for an agent to action
 */
export const createLoginIssueCase = (createLoginIssueCaseBody: CreateLoginIssueCaseBody) => {
  return customAxios<void>({
    url: `/help/login-issue`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createLoginIssueCaseBody,
  });
};

export const getCreateLoginIssueCaseMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLoginIssueCase>>,
    TError,
    { data: CreateLoginIssueCaseBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createLoginIssueCase>>,
  TError,
  { data: CreateLoginIssueCaseBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createLoginIssueCase>>,
    { data: CreateLoginIssueCaseBody }
  > = (props) => {
    const { data } = props ?? {};

    return createLoginIssueCase(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateLoginIssueCaseMutationResult = NonNullable<Awaited<ReturnType<typeof createLoginIssueCase>>>;
export type CreateLoginIssueCaseMutationBody = CreateLoginIssueCaseBody;
export type CreateLoginIssueCaseMutationError = unknown;

export const useCreateLoginIssueCase = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLoginIssueCase>>,
    TError,
    { data: CreateLoginIssueCaseBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createLoginIssueCase>>,
  TError,
  { data: CreateLoginIssueCaseBody },
  TContext
> => {
  const mutationOptions = getCreateLoginIssueCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
