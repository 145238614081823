'use client';

import { tamaguiConfig, TamaguiProvider, type TamaguiProviderProps } from '@cxnpl/ui';
import type { ReactNode } from 'react';
import { PortalProvider } from './PortalProvider';

export interface StorybookUIProviderProps extends Partial<TamaguiProviderProps> {
  children: ReactNode;
}

export function StorybookUIProvider({ config, defaultTheme, children, ...props }: StorybookUIProviderProps) {
  return (
    <TamaguiProvider config={config ?? tamaguiConfig} defaultTheme={defaultTheme ?? 'light'} {...props}>
      <PortalProvider>{children}</PortalProvider>
    </TamaguiProvider>
  );
}
