import { type PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import type { Chat } from './types';

export const selectChats = (state: RootState) => state.chat.chats;
export const selectChatWindowOpen = (state: RootState) => state.chat.chatWindowOpen;
export const selectContactId = (state: RootState) => state.chat.contactId;
export const selectScreenIndex = (state: RootState) => state.chat.screenIndex;
export const selectPiiWarningVisible = (state: RootState) => state.chat.piiWarningVisible;
export const selectDraftMessage = (state: RootState) => state.chat.draftMessage;

export const selectCurrentChat = createSelector([selectChats, selectContactId], (chats, contactId) =>
  chats.find((chat) => chat.contactId === contactId)
);

export const selectUnreadChats = createSelector(
  [selectChats],
  (chats) => chats.filter((chat) => chat.unreadMessages > 0).length
);

export interface ChatState {
  /** Array of chats belonging to the user */
  chats: Chat[];
  /** Wether the chat window is open or not */
  chatWindowOpen: boolean;
  /** The contact ID of the current selected chat */
  contactId: string;
  /** The index of the screen to show in the chat window */
  screenIndex: number;
  /** Wether the PII warning alert is visible or not */
  piiWarningVisible: boolean;
  /** Draft message to save if the user navigates away from the chat */
  draftMessage: string;
}

export const initialChatState: ChatState = {
  chats: [],
  chatWindowOpen: false,
  contactId: '',
  screenIndex: 0,
  piiWarningVisible: true,
  draftMessage: '',
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: initialChatState,
  reducers: {
    setScreenIndex: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      state.screenIndex = action.payload.index;
    },
    decrementScreenIndex: (state) => {
      if (state.screenIndex > 0) {
        state.screenIndex -= 1;
      }
    },
    setContactId: (
      state,
      action: PayloadAction<{
        contactId: string;
      }>
    ) => {
      state.contactId = action.payload.contactId;
    },
    setPiiWarningVisible: (
      state,
      action: PayloadAction<{
        piiWarningVisible: boolean;
      }>
    ) => {
      state.piiWarningVisible = action.payload.piiWarningVisible;
    },
    setDraftMessage: (
      state,
      action: PayloadAction<{
        message: string;
      }>
    ) => {
      state.draftMessage = action.payload.message;
    },
    addChat: (
      state,
      action: PayloadAction<{
        chat: Chat;
      }>
    ) => {
      state.chats.push(action.payload.chat);
    },
    setWebsocketConnected: (
      state,
      action: PayloadAction<{
        contactId: Chat['contactId'];
        websocketConnected: Chat['websocketConnected'];
      }>
    ) => {
      const chat = state.chats.find((_chat) => _chat.contactId === action.payload.contactId);

      if (!chat) {
        return;
      }

      chat.websocketConnected = action.payload.websocketConnected;
    },
    addMessages: (
      state,
      action: PayloadAction<{
        contactId: Chat['contactId'];
        messages: Chat['messages'];
      }>
    ) => {
      const chat = state.chats.find((_chat) => _chat.contactId === action.payload.contactId);

      if (!chat) {
        return;
      }

      chat.messages = [...chat.messages, ...action.payload.messages]
        .filter((_chat, index, self) => index === self.findIndex((_c) => _c.id === _chat.id))
        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

      const firstMessage = chat.messages[0];

      if (firstMessage) {
        chat.lastMessageDate = firstMessage.timestamp;
        chat.lastMessageContent = firstMessage.text;
      }
    },
    setIsTyping: (state, action: PayloadAction<{ contactId: Chat['contactId']; isTyping: Chat['isTyping'] }>) => {
      const chat = state.chats.find((_chat) => _chat.contactId === action.payload.contactId);

      if (!chat) {
        return;
      }

      chat.isTyping = action.payload.isTyping;
    },
    closeChat: (state, action: PayloadAction<{ contactId: Chat['contactId'] }>) => {
      const chat = state.chats.find((_chat) => _chat.contactId === action.payload.contactId);

      if (!chat) {
        return;
      }

      chat.state = 'CLOSED';
    },
    setCategory: (state, action: PayloadAction<{ contactId: Chat['contactId']; category: Chat['category'] }>) => {
      const chat = state.chats.find((_chat) => _chat.contactId === action.payload.contactId);

      if (!chat) {
        return;
      }

      chat.category = action.payload.category;
    },
    addUnreadMessage: (state, action: PayloadAction<{ contactId: Chat['contactId'] }>) => {
      const chat = state.chats.find((_chat) => _chat.contactId === action.payload.contactId);

      if (!chat) {
        return;
      }

      chat.unreadMessages += 1;
    },
    resetUnreadMessages: (state, action: PayloadAction<{ contactId: Chat['contactId'] }>) => {
      const chat = state.chats.find((_chat) => _chat.contactId === action.payload.contactId);

      if (!chat) {
        return;
      }

      chat.unreadMessages = 0;
    },
    setChatWindowOpen: (
      state,
      action: PayloadAction<{
        chatWindowOpen: boolean;
      }>
    ) => {
      state.chatWindowOpen = action.payload.chatWindowOpen;
    },
  },
});

export const {
  setScreenIndex,
  decrementScreenIndex,
  setContactId,
  setPiiWarningVisible,
  setDraftMessage,
  addChat,
  setWebsocketConnected,
  addMessages,
  setIsTyping,
  closeChat,
  setCategory,
  addUnreadMessage,
  resetUnreadMessages,
  setChatWindowOpen,
} = chatSlice.actions;

export default chatSlice.reducer;
