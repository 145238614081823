/* eslint-disable @typescript-eslint/require-await -- consistent types */
import type { Storage } from './types';

export const storage: Storage = {
  customerNumber: {
    // Not used in web
    async get() {
      return null;
    },
    // Not used in web
    async set() {
      return '';
    },
    // Not used in web
    async delete() {
      null;
    },
  },
  deviceId: {
    async get() {
      const deviceId = localStorage.getItem('deviceId');
      return deviceId;
    },
    async set(deviceId: string) {
      localStorage.setItem('deviceId', deviceId);
      return deviceId;
    },
    async delete() {
      localStorage.removeItem('deviceId');
    },
  },
} as const;
