'use client';

import { getAnalytics, logEvent as logFirebaseEvent, isSupported } from 'firebase/analytics';
import type { AnalyticsContextValue } from '../AnalyticsContext';

// Ignore sending product analytics events in development and test environments
const IS_DEV_ENV = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

interface EventTypes {
  complete_signup: never;

  complete_business_onboarding: never;

  page_view: {
    screen_name?: string;
    page_location?: string;
    page_path?: string;
    context?: AnalyticsContextValue;
  };

  logout: never;

  login: {
    method?: string;
  };

  open_account: {
    product_name?: string;
    product_type?: string;
  };

  open_arrangement: {
    arrangement_id?: string;
    product_name: string;
    product_type?: string;
    amount?: string;
    term_length?: string;
    term_type?: string;
  };

  order_card: {
    account_type?: string;
    screen_name?: string;
  };

  card_freeze: never;

  card_unfreeze: never;

  card_activate: never;

  card_pin_set: never;

  card_pin_change: never;

  card_details_show: never;
  card_details_hide: never;

  card_apply: never;

  order_card_start: {
    entrypoint: 'carousel' | 'under_carousel' | 'account_menu';
  };

  submit_business_onboarding: never;

  submit_business_onboarding_details: {
    application_step: string;
  };

  submit_loan_application_business_financials: {
    application_step: string;
  };

  submit_loan_application_business_details: {
    application_step: string;
  };

  submit_loan_application_additional_details: {
    application_step: string;
  };

  submit_loan_application_vehicle_details: {
    application_step: string;
  };

  submit_loan_application_proposed_arrangement: {
    amount: string;
    balloonAmount?: string;
  };

  submit_loan_application_consent: never;

  submit_loan_application_documents: never;

  submit_loan_application_id_check: never;

  submit_loan_application_initial: never;

  submit_loan_application_purpose: never;

  start_business_onboarding: never;

  start_loan_application: {
    product_name?: string;
    product_type: string;
    amount?: string;
    balloonAmount?: string;
  };

  start_signup: never;

  role_update: never;
  invite_resend: never;
  invite_revoke: never;

  view_product: {
    product_name: string;
    product_type: string;
    product_key?: string;
    product_version?: string;
  };

  withdraw_loan_application: {
    product_name?: string;
    product_type?: string;
    amount?: string;
  };

  start_lending_biometrics_scan: {
    type: 'DRIVER_LICENSE' | 'SELFIE';
  };

  complete_lending_biometrics__scan: {
    type: 'DRIVER_LICENSE' | 'SELFIE';
    time_taken: number;
  };

  financial_assistance_start: never;
  financial_assistance_submit: never;
}

// FIXME: This function should be renamed to `logFirebaseEvent` or be agnostic to the analytics services we use
export async function logEvent<Key extends keyof EventTypes>(
  eventName: Key,
  eventParams?: EventTypes[Key] extends never ? undefined : EventTypes[Key]
): Promise<void> {
  if (IS_DEV_ENV) {
    // eslint-disable-next-line no-console -- Allow console.log for development
    console.debug('[Analytics] logEvent', eventName, eventParams);
    return;
  }
  try {
    const supported = await isSupported();
    if (!supported) {
      return;
    }
    const analytics = getAnalytics();
    logFirebaseEvent(analytics, eventName as 'anything', eventParams); // hacky typing to allow any string as event name - issue with using 'login' as event name
  } catch (error) {
    // no-op
  }
}
