'use client';

import { AlertDialog, Loader, YStack, useForm } from '@cxnpl/ui';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from '@cxnpl/api/axios';
import { getErrorMessage } from '@cxnpl/api/axios';
import type { MFAScope } from '@cxnpl/api/api.schemas';
import { useStartMfa } from '@cxnpl/api/mfa/mfa';
import { useVerifyPassword } from '@cxnpl/api/users/users';
import type { BRAND } from 'zod';
import { useToast } from 'app/hooks';
import type { AnalyticsContextValue } from 'app/analytics';
import { BaseTrackScreenViewWrapper, EVENT_NAME } from 'app/analytics';
import { StepUpAuthContext } from './StepUpAuthContext';
import type { StepUpAuthContextValue } from './StepUpAuthContext';
import type { MfaFormProps } from './MfaForm';
import { MfaForm, MfaFormSchema } from './MfaForm';
import type { PasswordFormProps } from './PasswordForm';
import { PasswordForm, PasswordFormSchema } from './PasswordForm';

const MFAScopeDescriptions: {
  [scope in MFAScope]: string;
} = {
  'payee:add':
    "We've noticed you're sending money to someone you've never paid before.\n\nTo keep your account secure, we've sent a security code to your nominated mobile number.",
  'user:updatePersonalDetails':
    "To keep your account secure, we've sent a security code to your nominated mobile number.",
  'card:setPin': "To keep your account secure, we've sent a security code to your nominated mobile number.",
  'card:getCvv': "To keep your account secure, we've sent a security code to your nominated mobile number.",
  'card:getPan': "To keep your account secure, we've sent a security code to your nominated mobile number.",
  'card:getCvvPan': "To keep your account secure, we've sent a security code to your nominated mobile number.",
  'businessEntity:updateConfig':
    "To keep your account secure, we've sent a security code to your nominated mobile number.",
  'payment:externalUnknownPayee':
    "We've noticed you're sending money to someone you've never paid before.\n\nTo keep your account secure, we've sent a security code to your nominated mobile number.",
  'payto:respondToMandate': "To keep your account secure, we've sent a security code to your nominated mobile number.",
  cdr: "To keep your account secure, we've sent a security code to your nominated mobile number.",
  login: "To keep your account secure, we've sent a security code to your nominated mobile number.",
};

export const StepUpAuthProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { showToast } = useToast();
  const [mode, setMode] = useState<'PASSWORD' | 'MFA'>('MFA');
  const [pageContext, setPageContext] = useState<AnalyticsContextValue>('Other');
  const [pageName, setPageName] = useState<string>('');
  const onSubmitCb = useRef<
    | Parameters<StepUpAuthContextValue['requireMfa']>[0]['onSubmit']
    | Parameters<StepUpAuthContextValue['requirePassword']>[0]['onSubmit']
    | undefined
  >();
  const onErrorCb = useRef<Parameters<StepUpAuthContextValue['requireMfa']>[0]['onError'] | undefined>();
  const onInvalidCodeCb = useRef<Parameters<StepUpAuthContextValue['requireMfa']>[0]['onInvalidCode'] | undefined>();
  const onCancelCb = useRef<
    | Parameters<StepUpAuthContextValue['requireMfa']>[0]['onCancel']
    | Parameters<StepUpAuthContextValue['requirePassword']>[0]['onCancel']
    | undefined
  >();
  const mfaId = useRef<string | undefined>();
  const mfaScope = useRef<MFAScope | undefined>();
  const [canResend, setCanResend] = useState<boolean>(true);
  const [autoSubmit, setAutoSubmit] = useState<boolean>(true);
  const [keepOpenWhileSubmitting, setKeepOpenWhileSubmitting] = useState<boolean>(false);
  const { mutateAsync: startMfa, isPending: isLoadingStartMfa } = useStartMfa();
  const hasInitialized = useRef<boolean>(false);
  const { mutateAsync: verifyPassword } = useVerifyPassword();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const mfaForm = useForm(MfaFormSchema, {
    reValidateMode: 'onSubmit',
  });
  const passwordForm = useForm(PasswordFormSchema, {
    reValidateMode: 'onSubmit',
  });

  const cleanup = useCallback(() => {
    setShowDialog(false);
    setPageContext('Other');
    setPageName('');
    setCanResend(true);
    setAutoSubmit(true);
    setKeepOpenWhileSubmitting(false);
    onSubmitCb.current = undefined;
    onErrorCb.current = undefined;
    onInvalidCodeCb.current = undefined;
    onCancelCb.current = undefined;
    mfaId.current = undefined;
    mfaScope.current = undefined;
    mfaForm.reset({
      code: '',
    });
    passwordForm.reset({
      password: '',
    });
  }, [mfaForm, passwordForm]);

  const onCancel = useCallback(() => {
    onCancelCb.current?.();
    cleanup();
  }, [cleanup]);

  const initializeMfa = useCallback(
    async (scope: MFAScope) => {
      // Start new mfa session with given scope
      const { challengeId } = await startMfa({
        scope,
      });
      if (!challengeId) {
        throw Error('An unexpected error has occurred. Please try again.');
      }
      mfaId.current = challengeId as string;
    },
    [startMfa]
  );

  const onResend: MfaFormProps['onResend'] = async ({ startCountdown }) => {
    if (!mfaScope.current) {
      return;
    }
    startCountdown(30);
    mfaForm.reset();
    try {
      await initializeMfa(mfaScope.current);
    } catch (e) {
      // Failed to start mfa session
      showToast({ title: getErrorMessage(e), severity: 'danger' });
    }
  };

  const onSubmitMFA: MfaFormProps['onSubmit'] = async (values, { startCountdown }) => {
    if (!mfaId.current) {
      return;
    } // Mfa id should be set at this point
    try {
      setIsSubmitting(true);
      //Default behaviour closes the dialog while submitting
      if (!keepOpenWhileSubmitting) {
        setShowDialog(false);
      }
      await (onSubmitCb.current as Parameters<StepUpAuthContextValue['requireMfa']>[0]['onSubmit'] | undefined)?.({
        mfaId: mfaId.current,
        mfaCode: values.code,
      });
      startCountdown(0);
      //Alternative behaviour, closes dialog after the submit has not failed
      if (keepOpenWhileSubmitting) {
        setShowDialog(false);
      }
      cleanup();
    } catch (e) {
      const err = e as AxiosError;
      const message = getErrorMessage(e); //Have to get the message through the function, otherwise accessing raw via casting as AxiosError and applying string operations would silently fail
      // If invalid mfa
      //TODO: Improve the logic that identifies the error being due to incorrect MFA code. https://constantinople.atlassian.net/browse/PWA-961
      if (
        (err.response?.status === 400 || err.response?.status === 401 || err.response?.status === 403) &&
        message.toLowerCase().includes('mfa')
      ) {
        setShowDialog(true);
        mfaForm.setValue('code', '' as string & BRAND<'passwordCode'>);
        mfaForm.setError('code', { message: getErrorMessage(e), type: 'value' });
        await onInvalidCodeCb.current?.(e);
      } else {
        await onErrorCb.current?.(e);
        startCountdown(0);
        onCancel();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitPassword: PasswordFormProps['onSubmit'] = async (values) => {
    // Verify password
    try {
      setIsSubmitting(true);
      const validPassword = await verifyPassword({ data: { password: values.password } });
      if (!validPassword) {
        passwordForm.setError('password', {
          message: t('stepup.passwordForm.invalidPasswordErrorMessage'),
          type: 'value',
        });
        return;
      }
    } catch (e) {
      passwordForm.setError('password', {
        message: getErrorMessage(e, {
          fallbackErrorMessage: t('stepup.passwordForm.invalidPasswordErrorMessage'),
        }),
        type: 'value',
      });
      return;
    } finally {
      setIsSubmitting(false);
    }
    setShowDialog(false);
    try {
      // Password is valid, call callback
      await (onSubmitCb.current as Parameters<StepUpAuthContextValue['requirePassword']>[0]['onSubmit'] | undefined)?.(
        values.password
      );
    } finally {
      cleanup();
    }
  };

  const value: StepUpAuthContextValue = useMemo(
    () => ({
      requireMfa: async (config) => {
        setMode('MFA');
        setShowDialog(true);
        setPageContext(config.pageContext ?? 'Other');
        setPageName(config.pageName ?? 'VerifyOTP');
        onSubmitCb.current = config.onSubmit;
        onErrorCb.current = config.onError;
        onInvalidCodeCb.current = config.onInvalidCode;
        onCancelCb.current = config.onCancel;
        setCanResend(config.allowResend ?? true);
        setAutoSubmit(config.autoSubmit ?? true);
        setKeepOpenWhileSubmitting(!!config.keepOpenWhileSubmitting);
        mfaScope.current = config.scope;
        // Start new mfa session with given scope
        try {
          await initializeMfa(config.scope);
          hasInitialized.current = true;
        } catch (e) {
          // Failed to start mfa session
          showToast({ title: getErrorMessage(e), severity: 'danger' });
          onCancel();
        }
      },
      requirePassword: (config) => {
        setMode('PASSWORD');
        setPageContext(config.pageContext ?? 'Other');
        setPageName(config.pageName ?? 'VerifyPassword');
        setShowDialog(true);
        onSubmitCb.current = config.onSubmit;
        onErrorCb.current = undefined;
        onInvalidCodeCb.current = undefined;
        onCancelCb.current = config.onCancel;
        mfaScope.current = undefined;
        mfaId.current = undefined;
      },
      isSubmitting,
    }),
    [initializeMfa, isSubmitting, onCancel, showToast]
  );

  return (
    <>
      <AlertDialog
        testID="step-up-auth"
        showDialog={showDialog}
        containerProps={{
          padding: '$space.4xl',
          maxWidth: 468,
        }}
      >
        <BaseTrackScreenViewWrapper eventName={EVENT_NAME} pageContext={pageContext} pageName={pageName}>
          {/* Only show loader on initial load. Resends that occur afterwards should not show loader */}
          {isLoadingStartMfa && !hasInitialized.current ? (
            <Loader />
          ) : (
            <YStack>
              {mode === 'MFA' ? (
                <MfaForm
                  title={t('auth.components.mfaForm.title')}
                  description={
                    mfaScope.current ? MFAScopeDescriptions[mfaScope.current] : t('stepup.mfaForm.defaultDescription')
                  }
                  onSubmit={onSubmitMFA}
                  onResend={canResend ? onResend : undefined}
                  onCancel={onCancel}
                  autoSubmit={autoSubmit}
                  form={mfaForm}
                />
              ) : (
                <PasswordForm
                  title={t('stepup.passwordForm.title')}
                  description={t('stepup.passwordForm.description')}
                  onSubmit={onSubmitPassword}
                  onCancel={onCancel}
                  form={passwordForm}
                />
              )}
            </YStack>
          )}
        </BaseTrackScreenViewWrapper>
      </AlertDialog>
      <StepUpAuthContext.Provider value={value}>{children}</StepUpAuthContext.Provider>
    </>
  );
};
