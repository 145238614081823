'use client';

import { useTranslation } from 'react-i18next';
import { Button, Text } from '@cxnpl/ui';
import { FullScreenError } from './components';

export function UnexpectedError({ errorTrackerId }: { errorTrackerId?: string }) {
  const { t } = useTranslation();

  return (
    <FullScreenError
      variant="with-logo-and-background"
      title={t('common.errorBoundary.title.unexpected')}
      message={
        errorTrackerId
          ? t('common.errorBoundary.message.unexpectedWithTrackerId')
          : t('common.errorBoundary.message.unexpected')
      }
    >
      {errorTrackerId ? <Text>{errorTrackerId}</Text> : null}
      <Button
        onPress={() => {
          //At this error stage, a brutal recovery will be attempted by refreshing the whole page to /home (and, if logged out, to /login by proxy)
          //DO NOT CHANGE THIS TO A ROUTER FUNCTION. IT WON'T WORK, BECAUSE A COMPLETE RELOAD IS NEEDED.
          window.location.replace('/home');
        }}
      >
        {t('common.errorBoundary.buttons.backToHome')}
      </Button>
    </FullScreenError>
  );
}
