import { useToastController } from '@tamagui/toast';

export const useToast = () => {
  const toast = useToastController();

  const hideToast = () => {
    toast.hide();
  };
  const showToast = ({
    title,
    severity,
  }: {
    title: string;
    severity: 'danger' | 'info' | 'success' | 'warning' | 'attention';
  }) => {
    toast.show(title, {
      duration: 4000,
      customData: { severity },
    });
  };

  return { showToast, hideToast };
};

export default useToast;
