'use client';

import { useContext } from 'react';
import { StepUpAuthContext, type StepUpAuthContextValue } from './StepUpAuthContext';

export const useStepUpAuth = (): StepUpAuthContextValue => {
  const context = useContext(StepUpAuthContext);
  if (context === undefined) {
    throw new Error('useStepUpAuth must be used within a StepUpAuthProvider');
  }
  return context;
};
