// todo: can be removed later once the BE is integrated
import { createSlice } from '@reduxjs/toolkit';

export interface FrankieOneInfo {
  entityId: string;
}

const initialF1ConnectState: FrankieOneInfo = {
  entityId: '', // todo: will be provided from the BE
  // entityId: 'f7d4c1d3-a540-60b0-60c3-318531467495', // todo: will be provided from the BE
  // entityId: "bfa27d08-2dd7-4f37-f214-72a2494b5e1c" // passport
  // entityId: '23877c97-124c-3dec-027b-0b1b49e3e924', //incode
};

export const F1ConnectSlice = createSlice({
  name: 'FrankieOneConnect',
  initialState: initialF1ConnectState,
  reducers: {},
});

export default F1ConnectSlice.reducer;
