import type { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { shutdown as intercomShutdown } from '@intercom/messenger-js-sdk';
import type { AuthenticationResultType, Refresh200 } from '@cxnpl/api/api.schemas';
import type { AppStore } from '../store';
import { tokenInfoNoInterceptor } from '../api/no-interceptor/tokenInfo';
import { resetTokenInfo, setTokenInfo } from '../features/tokenInfo/tokenInfoSlice';
import { refreshTokenNoInterceptor } from '../api/no-interceptor/refreshToken';
import { logoutNoInterceptor } from '../api/no-interceptor/logout';
import { appClient } from '../utils/appClient';
import { setAuthState } from '../features/auth/authSlice';
import { axiosInstanceMutex, refreshTokenMutex } from './mutex';

const tokenLoginEndpoints = ['/login'];
const tokenLogoutEndpoints = ['/login/revokeSession'];
const tokenRefreshEndpoints = ['/login/refresh', '/v2/session/refresh'];
const tokenEndpoints = [...tokenLoginEndpoints, ...tokenRefreshEndpoints, ...tokenLogoutEndpoints];

const dispatchAuthInfo = (refreshResponse: AuthenticationResultType | Refresh200, store: AppStore) => {
  // If the app is a web app, we don't need to update the token info
  if (appClient === 'WEB') {
    return;
  }

  if (typeof refreshResponse === 'object' && 'AccessToken' in refreshResponse) {
    store.dispatch(
      setAuthState({
        accessToken: refreshResponse.AccessToken,
        idToken: refreshResponse.IdToken,
        refreshToken: refreshResponse.RefreshToken,
      })
    );
  }
};

const fetchAndDispatchTokenInfo = async (store: AppStore) => {
  // If the app is a mobile app, we don't need to fetch token info
  if (appClient === 'MOBILE') {
    return;
  }
  const tokenInfo = await tokenInfoNoInterceptor();
  if (!tokenInfo) {
    store.dispatch(resetTokenInfo());
  } else {
    store.dispatch(setTokenInfo(tokenInfo));
  }
};

const logoutAndRefresh = async (store: AppStore, refreshLoggedOutPage: () => void) => {
  try {
    const auth0Enabled = store.getState().featureFlag.enableV2Auth0;
    store.dispatch(resetTokenInfo());
    intercomShutdown();
    await logoutNoInterceptor(auth0Enabled);
  } catch (e) {
    // Do nothing
  } finally {
    refreshLoggedOutPage();
  }
};

const isTokenExpired = (expiry: number) => {
  return expiry <= Date.now();
};

// Function to set up our axios instance with interceptors
//  - Automatically refresh token if access token has expired
const configureAxios = <AxiosT extends AxiosInstance>(
  axiosInstance: AxiosT,
  store: AppStore,
  refreshLoggedOutPage: () => void
) => {
  const requestInterceptorId = axiosInstance.interceptors.request.use(async (config) => {
    // Wait until any refresh token called from response interceptor is done.
    // Don't wait for refreshInstanceMutex to unlock as the external call acquires a lock, meaning it would deadlock here.
    await axiosInstanceMutex.waitForUnlock();

    const authInfo = store.getState().auth;

    // Adds deviceId headers
    const deviceId = authInfo.deviceId;
    if (deviceId) {
      config.headers.deviceId = deviceId;
    }

    // Set headers for the mobile app
    if (authInfo.accessToken && authInfo.idToken && appClient === 'MOBILE') {
      config.headers.Authorization = authInfo.idToken;
      config.headers.AccessAuthorizationKey = authInfo.accessToken;
      config.headers.EntityAccountAuthorizationKey = authInfo.entityAccountId;
    }
    return config;
  });

  const responseInterceptorId = axiosInstance.interceptors.response.use(
    async (response: AxiosResponse) => {
      // If the request is a token request, i.e. login or refresh, update the token info
      if (tokenEndpoints.includes(response.config.url || '')) {
        await fetchAndDispatchTokenInfo(store);
      }

      // Return a successful response
      return response;
    },
    async (error: AxiosError) => {
      //This is called every time there is an axios error, so it tries to recover from it
      const originalConfig = error.config;

      // Type guard, should never happen
      if (!originalConfig) {
        return Promise.reject(error);
      }

      // If the request failed due to 401, it may be because of an expired token. If so, handle acquiring a new set of tokens
      if (
        error.response &&
        error.response.status === 401 &&
        !tokenEndpoints.includes(originalConfig.url || '') // Ignore if the request is related to login or refresh
      ) {
        // Check if tokens are expired
        const state = store.getState();
        const tokenInfo = state.tokenInfo;

        // If no token expiry is set, the token is not there hence return.
        // Should never happen
        if (!tokenInfo.expiry) {
          await logoutAndRefresh(store, refreshLoggedOutPage);
          return Promise.reject(error);
        }

        const tokenIsExpired = isTokenExpired(tokenInfo.expiry);

        // If token is expired, refresh it
        if (tokenIsExpired) {
          // Wait until any external refresh token call is done, as that would invalidate the current set of tokens
          await refreshTokenMutex.waitForUnlock();
          if (!axiosInstanceMutex.isLocked()) {
            // Block other requests from running (request interceptor) while refresh token is occurring
            const release = await axiosInstanceMutex.acquire();
            try {
              const auth0Enabled = !!state.featureFlag.enableV2Auth0;
              const refreshResponse = await refreshTokenNoInterceptor({ auth0Enabled }); // Refresh token
              dispatchAuthInfo(refreshResponse, store);
              await fetchAndDispatchTokenInfo(store);
              release();
              return axiosInstance(originalConfig); // Retry original request
            } catch (err) {
              // Refresh token failed, logout user and refresh the page
              release();
              await logoutAndRefresh(store, refreshLoggedOutPage);
              return Promise.reject(error);
            }
          } else {
            // A parallel request. Token might be refreshed now, retry the original request
            await axiosInstanceMutex.waitForUnlock();
            return axiosInstance(originalConfig);
          }
        }
      }

      // Refresh was called directly and failed. Just logout and redirect the user to the login page (with current route as redirect param)
      if (error.response && tokenRefreshEndpoints.includes(originalConfig.url || '')) {
        await logoutAndRefresh(store, refreshLoggedOutPage);
        return Promise.reject(error);
      }

      // Default response for errors unrelated to expired token
      return Promise.reject(error);
    }
  );
  return { requestInterceptorId, responseInterceptorId };
};

export default configureAxios;
