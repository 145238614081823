/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type {
  CheckAppVersion200,
  CheckAppVersionParams,
  ClientConfigKey,
  GetBanner200,
  GetBannerParams,
  GetClientConfig200,
  GetFeatureToggles200,
} from '../api.schemas';
import { customAxios } from '../axios';

/**
 * Checks if the user's app version is up to date
 */
export const checkAppVersion = (params: CheckAppVersionParams, signal?: AbortSignal) => {
  return customAxios<CheckAppVersion200>({ url: `/config/app-version`, method: 'GET', params, signal });
};

export const getCheckAppVersionQueryKey = (params: CheckAppVersionParams) => {
  return [`/config/app-version`, ...(params ? [params] : [])] as const;
};

export const getCheckAppVersionQueryOptions = <TData = Awaited<ReturnType<typeof checkAppVersion>>, TError = unknown>(
  params: CheckAppVersionParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkAppVersion>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckAppVersionQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkAppVersion>>> = ({ signal }) =>
    checkAppVersion(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof checkAppVersion>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckAppVersionQueryResult = NonNullable<Awaited<ReturnType<typeof checkAppVersion>>>;
export type CheckAppVersionQueryError = unknown;

export const useCheckAppVersion = <TData = Awaited<ReturnType<typeof checkAppVersion>>, TError = unknown>(
  params: CheckAppVersionParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkAppVersion>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCheckAppVersionQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets all public facing config
 - feature toggles
public endpoint!
 */
export const getFeatureToggles = (signal?: AbortSignal) => {
  return customAxios<GetFeatureToggles200>({ url: `/config/feature-flags`, method: 'GET', signal });
};

export const getGetFeatureTogglesQueryKey = () => {
  return [`/config/feature-flags`] as const;
};

export const getGetFeatureTogglesQueryOptions = <
  TData = Awaited<ReturnType<typeof getFeatureToggles>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeatureToggles>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFeatureTogglesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeatureToggles>>> = ({ signal }) =>
    getFeatureToggles(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFeatureToggles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFeatureTogglesQueryResult = NonNullable<Awaited<ReturnType<typeof getFeatureToggles>>>;
export type GetFeatureTogglesQueryError = unknown;

export const useGetFeatureToggles = <
  TData = Awaited<ReturnType<typeof getFeatureToggles>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeatureToggles>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFeatureTogglesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets specific public facing client config
 - client config
 */
export const getClientConfig = (configName: ClientConfigKey, signal?: AbortSignal) => {
  return customAxios<GetClientConfig200>({ url: `/config/client-config/${configName}`, method: 'GET', signal });
};

export const getGetClientConfigQueryKey = (configName: ClientConfigKey) => {
  return [`/config/client-config/${configName}`] as const;
};

export const getGetClientConfigQueryOptions = <TData = Awaited<ReturnType<typeof getClientConfig>>, TError = unknown>(
  configName: ClientConfigKey,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientConfig>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClientConfigQueryKey(configName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientConfig>>> = ({ signal }) =>
    getClientConfig(configName, signal);

  return { queryKey, queryFn, enabled: !!configName, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getClientConfig>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetClientConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getClientConfig>>>;
export type GetClientConfigQueryError = unknown;

export const useGetClientConfig = <TData = Awaited<ReturnType<typeof getClientConfig>>, TError = unknown>(
  configName: ClientConfigKey,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientConfig>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetClientConfigQueryOptions(configName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets specific public facing product config
 */
export const getProductConfig = (configId: string, signal?: AbortSignal) => {
  return customAxios<unknown>({ url: `/config/product-config/${configId}`, method: 'GET', signal });
};

export const getGetProductConfigQueryKey = (configId: string) => {
  return [`/config/product-config/${configId}`] as const;
};

export const getGetProductConfigQueryOptions = <TData = Awaited<ReturnType<typeof getProductConfig>>, TError = unknown>(
  configId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductConfig>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductConfigQueryKey(configId);

  const queryFn: QueryFunction = ({ signal }) => getProductConfig(configId, signal);

  return { queryKey, queryFn, enabled: !!configId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductConfig>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getProductConfig>>>;
export type GetProductConfigQueryError = unknown;

export const useGetProductConfig = <TData = Awaited<ReturnType<typeof getProductConfig>>, TError = unknown>(
  configId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductConfig>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductConfigQueryOptions(configId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets a banner message to display in the app
 */
export const getBanner = (params: GetBannerParams, signal?: AbortSignal) => {
  return customAxios<GetBanner200>({ url: `/config/banner`, method: 'GET', params, signal });
};

export const getGetBannerQueryKey = (params: GetBannerParams) => {
  return [`/config/banner`, ...(params ? [params] : [])] as const;
};

export const getGetBannerQueryOptions = <TData = Awaited<ReturnType<typeof getBanner>>, TError = unknown>(
  params: GetBannerParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBanner>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBannerQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBanner>>> = ({ signal }) => getBanner(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBanner>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBannerQueryResult = NonNullable<Awaited<ReturnType<typeof getBanner>>>;
export type GetBannerQueryError = unknown;

export const useGetBanner = <TData = Awaited<ReturnType<typeof getBanner>>, TError = unknown>(
  params: GetBannerParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBanner>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBannerQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
