import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface InviteState {
  inviteCountdown: number;
  inviteCountdownLastUpdated: number;
}

export const initialInviteState: InviteState = {
  inviteCountdown: 0,
  inviteCountdownLastUpdated: Date.now(),
};

export const inviteSlice = createSlice({
  name: 'invite',
  initialState: initialInviteState,
  reducers: {
    setInviteCountdown: (state, action: PayloadAction<InviteState['inviteCountdown']>) => {
      state.inviteCountdown = action.payload;
    },
    setInviteCountdownLastUpdated: (state, action: PayloadAction<InviteState['inviteCountdownLastUpdated']>) => {
      state.inviteCountdownLastUpdated = action.payload;
    },
  },
});

export const { setInviteCountdown, setInviteCountdownLastUpdated } = inviteSlice.actions;

export default inviteSlice.reducer;
