'use client';

import { useCallback } from 'react';
import { logError as logErrorAxios } from '@cxnpl/api/webapp/webapp';
import { useRouteHistory } from 'app/provider/RouteHistoryProvider/useRouteHistory';

const isDev = process.env.NODE_ENV === 'development';

const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  const language = navigator.language;
  const onlineStatus = navigator.onLine;
  const cookiesEnabled = navigator.cookieEnabled;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  return {
    userAgent,
    language,
    onlineStatus,
    cookiesEnabled,
    screenWidth,
    screenHeight,
    viewportWidth,
    viewportHeight,
  };
};

type ErrorLogFunction = (stackTrace: Error, errorTrackerId: string) => Promise<void>;

const useLogError = (): ErrorLogFunction => {
  const routeHistory = useRouteHistory();

  const logError: ErrorLogFunction = useCallback(
    async (error: Error, trackerId: string) => {
      try {
        if (isDev) {
          // eslint-disable-next-line no-console -- This is a logging mechanism
          console.error(error);
          return;
        }

        await logErrorAxios({
          logInfo: {
            stackTrace: {
              error: error.name,
              message: error.message,
              stack: error.stack,
            },
            routeHistory,
            appInfo: getBrowserInfo(),
            networkRequests: (performance.getEntriesByType('resource') as PerformanceResourceTiming[]).slice(-20),
            trackerId,
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console -- This is a logging mechanism
        console.error('Failed to log error');
      }
    },
    [routeHistory]
  );

  return logError;
};

export default useLogError;
