'use client';
import type { AppErrorProps } from '@cxnpl/ui';
import { YStack, XStack, AppError, useMedia, Image } from '@cxnpl/ui';
import branding from '@cxnpl/ui/brand';

export interface FullScreenErrorProps extends Omit<AppErrorProps, 'display' | 'overlayBackground'> {
  variant?: 'default' | 'always-inline' | 'with-logo-and-background';
  background?: 'app' | 'surface';
}

export function FullScreenError({ variant = 'default', background = 'app', ...props }: FullScreenErrorProps) {
  const media = useMedia();
  let display: 'inline' | 'inline-card' = media.tablet ? 'inline-card' : 'inline';
  if (variant === 'always-inline') {
    display = 'inline';
  }

  const errorCard = <AppError {...props} display={display} />;

  if (variant === 'default' || variant === 'always-inline') {
    return (
      <YStack
        backgroundColor={background === 'app' ? '$background/app' : '$background/surface'}
        flexGrow={1}
        paddingVertical="$8xl"
        alignItems="center"
        width="100%"
        testID="full-error"
      >
        {errorCard}
      </YStack>
    );
  }

  // with-logo-and-background
  return (
    <YStack flexGrow={1}>
      <XStack
        alignItems="center"
        backgroundColor="$background/surface"
        justifyContent="flex-start"
        paddingHorizontal="$space.lg"
        paddingVertical="$space.sm"
        $tablet={{
          paddingHorizontal: '$space.3xl',
        }}
      >
        <Image
          src={branding.assets.logo.src}
          width={branding.assets.logo.width}
          height={branding.assets.logo.height}
          alt=""
        />
      </XStack>
      <YStack flexGrow={1}>
        <Image
          src={branding.assets.background.src}
          style={{ objectFit: 'cover' }}
          alt=""
          webProps={{ priority: true, fill: true }}
        />
        <YStack
          backgroundColor="$background/surface"
          padding="$space.2xl"
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          $tablet={{
            backgroundColor: 'transparent',
            padding: '$space.8xl',
          }}
        >
          {errorCard}
        </YStack>
      </YStack>
    </YStack>
  );
}
