import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type Flags = Record<string, boolean>;

export type FeatureFlagState = Flags;

export const featureFlagInitialState: FeatureFlagState = {};

const featureFlagSlice = createSlice({
  name: 'featureFlag',
  initialState: featureFlagInitialState,
  reducers: {
    setFlags: (_, action: PayloadAction<FeatureFlagState>) => {
      return action.payload;
    },
  },
});

export const { setFlags } = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
