import { baseUrl } from '@cxnpl/api/baseUrl';
import axios from 'axios';
import type { AuthenticationResultType, Refresh200 } from '@cxnpl/api/api.schemas';
import { appClient } from 'app/utils/appClient';

/**
 * Refresh user token using a clean axios instance.
 * This does not have the interceptors attached to it and is intended to refresh the token
 * without any side effects. This method is NOT intended to be called within components.
 * Please instead use `useRefreshToken` from `app/api/internal/useRefreshToken.ts`.
 *
 * @param entityId - The entity ID of the business to refresh the token for.
 * @param auth0Enabled - Whether Auth0 is enabled.
 */
export async function refreshTokenNoInterceptor(
  { entityId, auth0Enabled }: { entityId?: string; auth0Enabled: boolean } = { auth0Enabled: false }
): Promise<AuthenticationResultType | Refresh200> {
  // Toggle refresh endpoint based on whether Auth0 is enabled
  const refreshUrl = auth0Enabled ? '/v2/session/refresh' : '/login/refresh';
  // not using axiosInstance means it will not hit interceptors
  const resp = await axios({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-PROTECTION': '1',
    },
    withCredentials: true,
    method: 'POST',
    url: refreshUrl,
    data: {
      entityId,
      appClient,
    },
  });
  return resp as AuthenticationResultType | Refresh200;
}
