import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { appClient } from 'packages/app/utils/appClient';
import { unsafeParseJwt } from './utils/parseJwt';

export interface AuthState {
  inactivityLogout: boolean;
  customerNumber: string;
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  biometricsEnabled?: boolean;
  deviceId?: string;
  entityAccountId?: string;
}

export const initialAuthState: AuthState = {
  inactivityLogout: false,
  customerNumber: '',
  accessToken: '',
  refreshToken: '',
  idToken: '',
  biometricsEnabled: false,
  deviceId: '',
  entityAccountId: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setInactivityLogout: (state, action: PayloadAction<AuthState['inactivityLogout']>) => {
      state.inactivityLogout = action.payload;
    },
    setCustomerNumber(state, action: PayloadAction<AuthState['customerNumber']>) {
      if (appClient === 'MOBILE') {
        state.customerNumber = action.payload;
      }
    },
    setAccessToken(state, action: PayloadAction<AuthState['accessToken']>) {
      // Cannot set accessToken on web
      if (appClient === 'MOBILE') {
        state.accessToken = action.payload;
      }
    },
    setRefreshToken(state, action: PayloadAction<AuthState['refreshToken']>) {
      // Cannot set refreshToken on web
      if (appClient === 'MOBILE') {
        state.refreshToken = action.payload;
      }
    },
    setIdToken(state, action: PayloadAction<AuthState['idToken']>) {
      // Cannot set idToken on web
      if (appClient === 'MOBILE') {
        state.idToken = action.payload;
      }
    },
    setDeviceId(state, action: PayloadAction<AuthState['deviceId']>) {
      state.deviceId = action.payload;
    },
    setBiometricsEnabled(state, action: PayloadAction<AuthState['biometricsEnabled']>) {
      state.biometricsEnabled = action.payload;
    },
    setEntityAccountId(state, action: PayloadAction<AuthState['entityAccountId']>) {
      state.entityAccountId = action.payload;
    },
    setAuthState(state, action: PayloadAction<Partial<AuthState>>) {
      // Cannot set tokens on web
      if (appClient !== 'MOBILE') {
        delete action.payload.accessToken;
        delete action.payload.refreshToken;
        delete action.payload.idToken;
        delete action.payload.customerNumber;
      }

      if (action.payload.idToken) {
        const jwtData = unsafeParseJwt(action.payload.idToken);
        if (jwtData) {
          action.payload.entityAccountId = jwtData.entityAccountId;
        }
      }

      return { ...state, ...action.payload };
    },
  },
});

export const {
  setInactivityLogout,
  setCustomerNumber,
  setAccessToken,
  setAuthState,
  setBiometricsEnabled,
  setDeviceId,
  setIdToken,
  setRefreshToken,
  setEntityAccountId,
} = authSlice.actions;

export default authSlice.reducer;
