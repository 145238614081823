'use client';

import { Button, FormField, H1, SchemaForm, Text, VisuallyHidden, XStack, YStack, useForm } from '@cxnpl/ui';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import type { UseFormReturn } from 'react-hook-form';
import { useMemo } from 'react';
import i18n from 'app/i18n/i18n.config';

export const PasswordFormSchema = z.object({
  username: FormField.Text().optional(),
  password: FormField.Password((zString) =>
    zString({
      required_error: `${i18n.t('stepup.passwordForm.passwordRequiredErrorMessage')}`,
    }).min(1, `${i18n.t('stepup.passwordForm.passwordRequiredErrorMessage')}`)
  ).describe(`${i18n.t('stepup.passwordForm.passwordInputLabel')}`),
});

export type PasswordFormFields = z.infer<typeof PasswordFormSchema>;

export type PasswordFormHelpers = UseFormReturn<PasswordFormFields>;

export interface PasswordFormProps {
  title: string;
  description?: string;
  onSubmit: (values: PasswordFormFields, helpers: PasswordFormHelpers) => void | Promise<void>;
  onCancel?: (helpers: PasswordFormHelpers) => void;
  /**
   * Optional form instance to use. Use if you want to control the form externally.
   */
  form?: UseFormReturn<PasswordFormFields>;
  testID?: string;
}

export const PasswordForm = ({
  title,
  description,
  onSubmit: onSubmitCallback,
  onCancel,
  form: controlledForm,
  testID,
}: PasswordFormProps) => {
  const { t } = useTranslation();

  const _form = useForm(PasswordFormSchema, {
    reValidateMode: 'onSubmit',
  });
  const form = controlledForm ?? _form;

  const helpers = useMemo<PasswordFormHelpers>(() => ({ ...form }), [form]);

  const onSubmit = async (values: PasswordFormFields) => {
    await onSubmitCallback(values, helpers);
  };

  return (
    <YStack testID={testID || 'password-form'} gap="$space.2xl">
      <H1 color="$foreground/surface-default">{title}</H1>
      {description ? (
        <Text variant="bodyMedium" flexWrap="wrap" color="$foreground/surface-subdued" whiteSpace="pre-wrap">
          {description}
        </Text>
      ) : null}
      <SchemaForm
        form={form}
        onSubmit={onSubmit}
        schema={PasswordFormSchema}
        formProps={{
          disabled: form.formState.isSubmitting,
        }}
        props={{
          password: {
            onChangeText: () => {
              form.clearErrors();
            },
          },
        }}
      >
        {(fields) => (
          <>
            {/*
              Rendering username field for accessibility, even though it won't be used at all. 
              https://www.chromium.org/developers/design-documents/create-amazing-password-forms/#use-hidden-fields-for-implicit-information
            */}
            <VisuallyHidden>{fields.username}</VisuallyHidden>
            {fields.password}
          </>
        )}
      </SchemaForm>
      <XStack alignItems="center" gap="$space.sm" justifyContent="center" flexWrap="wrap">
        <XStack flexGrow={1}>
          <Button
            fullWidth
            loading={form.formState.isSubmitting}
            disabled={form.formState.isSubmitting}
            mode="primary"
            variant="filled"
            size="lg"
            onPress={() => {
              void form.handleSubmit(onSubmit)();
            }}
          >
            {t('common.buttons.confirm')}
          </Button>
        </XStack>
        {onCancel ? (
          <XStack flexGrow={1}>
            <Button
              fullWidth
              disabled={form.formState.isSubmitting}
              mode="primary"
              variant="outlined"
              size="lg"
              onPress={() => {
                onCancel(helpers);
              }}
            >
              {t('common.buttons.cancel')}
            </Button>
          </XStack>
        ) : null}
      </XStack>
    </YStack>
  );
};
