/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import type { MFAScope, SendMfa200, SendMfaBody, StartMfa200 } from '../api.schemas';
import { customAxios } from '../axios';

/**
 * Used for step up authentication to initiate the flow
The challenges are resolved by the individual endpoints which are gating via MFA
 e.g. UserController.updatePersonalInfo
 */
export const startMfa = (scope: MFAScope) => {
  return customAxios<StartMfa200>({ url: `/mfa/start/${scope}`, method: 'POST' });
};

export const getStartMfaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof startMfa>>, { scope: MFAScope }> = (props) => {
    const { scope } = props ?? {};

    return startMfa(scope);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartMfaMutationResult = NonNullable<Awaited<ReturnType<typeof startMfa>>>;

export type StartMfaMutationError = unknown;

export const useStartMfa = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof startMfa>>, TError, { scope: MFAScope }, TContext> => {
  const mutationOptions = getStartMfaMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Used for sending MFA codes on behalf of Auth0
 */
export const sendMfa = (sendMfaBody: SendMfaBody) => {
  return customAxios<SendMfa200>({
    url: `/mfa/send`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: sendMfaBody,
  });
};

export const getSendMfaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sendMfa>>, TError, { data: SendMfaBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof sendMfa>>, TError, { data: SendMfaBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendMfa>>, { data: SendMfaBody }> = (props) => {
    const { data } = props ?? {};

    return sendMfa(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendMfaMutationResult = NonNullable<Awaited<ReturnType<typeof sendMfa>>>;
export type SendMfaMutationBody = SendMfaBody;
export type SendMfaMutationError = unknown;

export const useSendMfa = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof sendMfa>>, TError, { data: SendMfaBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof sendMfa>>, TError, { data: SendMfaBody }, TContext> => {
  const mutationOptions = getSendMfaMutationOptions(options);

  return useMutation(mutationOptions);
};
