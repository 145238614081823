'use client';
import { axiosInstance } from '@cxnpl/api/axios';
import { useEffect, useMemo, type ReactNode } from 'react';
import { Provider, type ProviderProps } from 'react-redux';
import { v4 as uuid } from 'uuid';
import type { AppStore } from 'app/store';
import { setupStore } from 'app/store';
import { setCustomerNumber, setDeviceId } from 'packages/app/features/auth/authSlice';
import configureAxios from '../../services/configureAxios';
import { storage } from './deviceStorage';

export const defaultStore = setupStore();

export interface StoreProviderProps extends Partial<ProviderProps> {
  children: ReactNode;
}

/**
 * This refresh page will always happen after axios has failed without possible recovery, so it needs to be a login page with a redirect.
 * Otherwise, trying to refresh a logged in route could lead to infinite attempts to token refresh
 */
const refreshLoggedOutPage = () => {
  const currentUrl = new URL(window.location.href);
  const redirectUrl = new URL(
    `/login?redirect=${encodeURIComponent(`${currentUrl.pathname}${currentUrl.search}`)}`,
    currentUrl
  );
  window.location.href = redirectUrl.href;
};

export function StoreProvider({ store: initialStore, children }: StoreProviderProps) {
  const store = useMemo(() => (initialStore as AppStore | undefined) ?? defaultStore, [initialStore]);

  // Initializes store with asynchronous data
  useEffect(() => {
    const initStore = async () => {
      // Get Device id
      let deviceId = await storage.deviceId.get();
      if (!deviceId) {
        deviceId = await storage.deviceId.set(uuid());
      }
      store.dispatch(setDeviceId(deviceId));

      // Get Customer number
      const customerNumber = await storage.customerNumber.get();
      if (customerNumber) {
        store.dispatch(setCustomerNumber(customerNumber));
      }
    };

    void initStore();
  }, [store]);

  useEffect(() => {
    // TODO: PWA-245 Refactor and move to the Authentication provider
    const { requestInterceptorId, responseInterceptorId } = configureAxios(axiosInstance, store, refreshLoggedOutPage);

    return () => {
      axiosInstance.interceptors.response.eject(requestInterceptorId);
      axiosInstance.interceptors.response.eject(responseInterceptorId);
    };
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
}
