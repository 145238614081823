'use client';

import type { ReactNode } from 'react';
import { ToastProvider as TamaguiToastProvider, Toast, ToastViewport, useToastState } from '@tamagui/toast';
import { AlertCircle, AlertTriangle, Check, Info } from '@cxnpl/ui/icons';
import { Text, View, styled } from '@cxnpl/ui';

const ToastView = styled(View, {
  gap: '$sm',
  alignItems: 'center',
  borderRadius: '$toast/radius/toast',
  paddingHorizontal: '$xl',
  paddingVertical: '$xs',

  variants: {
    severity: {
      danger: {
        color: '$alert/color/danger/alert-fg-danger',
        backgroundColor: '$alert/color/danger/alert-bg-danger',
      },
      info: {
        color: '$alert/color/info/alert-fg-info',
        backgroundColor: '$alert/color/info/alert-bg-info',
      },

      success: {
        color: '$alert/color/success/alert-fg-success',
        backgroundColor: '$alert/color/success/alert-bg-success',
      },
      warning: {
        color: '$alert/color/warning/alert-fg-warning',
        backgroundColor: '$alert/color/warning/alert-bg-warning',
      },
      attention: {
        color: '$alert/color/attention/alert-fg-attention',
        backgroundColor: '$alert/color/attention/alert-bg-attention',
      },
    },
  } as const,
  defaultVariants: {
    severity: 'info',
  },
});

const CurrentToast = () => {
  const currentToast = useToastState();

  if (!currentToast || currentToast.isHandledNatively) {
    return null;
  }
  const severity = currentToast.customData?.severity as 'danger' | 'info' | 'success' | 'warning' | 'attention';
  const toastIconMap: Record<string, ReactNode> = {
    danger: <AlertTriangle />,
    info: <Info />,
    success: <Check />,
    warning: <AlertCircle />,
    attention: <AlertCircle />,
  } as const;

  return (
    <Toast
      key={currentToast.id}
      duration={currentToast.duration}
      enterStyle={{ opacity: 0, scale: 0.5, y: -25 }}
      exitStyle={{ opacity: 0, scale: 1, y: -20 }}
      y={0}
      opacity={1}
      scale={1}
      animation="100ms"
      viewportName={currentToast.viewportName}
      testID="toast-alert"
    >
      <ToastView severity={severity}>
        {toastIconMap[severity]}
        <Text variant="bodySmallEm">{currentToast.title}</Text>
      </ToastView>
    </Toast>
  );
};

export interface ToastProviderProps {
  children: ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  return (
    <TamaguiToastProvider burntOptions={{ from: 'bottom' }}>
      <CurrentToast />
      {children}
      <ToastViewport top="$md" left={0} right={0} />
    </TamaguiToastProvider>
  );
}
