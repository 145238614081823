'use client';

// Ignore sending product analytics events in development and test environments
const IS_DEV_ENV = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

export function logAdobeEvent(eventName: string, event: string) {
  if (IS_DEV_ENV) {
    // eslint-disable-next-line no-console -- Allow console.log for development
    console.debug('[AdobeAnalytics] logEvent', eventName, event);
    return;
  }
  try {
    // https://experienceleague.adobe.com/en/docs/experience-manager-learn/sites/integrations/adobe-client-data-layer/data-layer-overview

    window.adobeDataLayer = window.adobeDataLayer || [];

    window.adobeDataLayer.push({
      event: eventName,
      web: {
        webPageDetails: {
          name: event,
          URL: document.location.toString(),
          path: document.location.pathname,
          platform: 'sbb',
          server: document.location.hostname,
        },
      },
    });
  } catch {
    // no-op
  }
}

// Adobe Analytics Data Layer global type
declare global {
  interface Window {
    adobeDataLayer?: AdobeDataLayer | unknown[];
  }

  interface AdobeDataLayer {
    push: (args: AdobePushType) => void;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- payload is non-specific
  interface AdobePushType extends Record<string, any> {
    event: string;
  }
}
