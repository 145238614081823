// For development and testing environments, the "baseUrl" is set to /api path.
// On the development environment this is proxied and handled with the local development server and local HTTP proxy
// built into the Next.js development server.
//
// For testing environment, the "baseUrl" is also set to the /api value so we can easily mock the API responses when
// running tests with Playwright.
//
// When app is deployed to AWS, the "baseUrl" is set to the actual full URL of the API server configured with
// the API_BASE_URL environment variable.

const isDev = process.env.NODE_ENV === 'development';
const isTesting = process.env.NODE_ENV === 'test';

export const baseUrl = isDev || isTesting ? '/api' : process.env.API_BASE_URL;
