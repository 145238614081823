'use client';

import type { MFAScope } from '@cxnpl/api/api.schemas';
import { createContext } from 'react';
import type { AnalyticsContextValue } from 'app/analytics';

export interface StepUpAuthContextValue {
  /**
   * Hook that starts an "mfa session" for a scope. It will perform the onSubmit function with the calls to the endpoint that needs to use the mfaCode.
   * If there is any error in the onSubmit it will be caught and 'funneled' to the onError function, so dealing with errors has to be done there.
   * Errors due to wrong MFA are specifically dealt by the dialog itself.
   * IMPORTANT CAVEAT: to trigger the MFA error, there has to be an AxiosError with status code 400 or 401 and have 'mfa' somewhere in the err.response.data.message (this is particularly important when mocking errors)
   * TODO: Improve the way this function deals with 400/401 errors when it's due to incorrect MFA code and different reasons. https://constantinople.atlassian.net/browse/PWA-961
   * @param config -- config object
   * @returns
   */
  requireMfa: (config: {
    /**
     * MFA scope
     */
    scope: MFAScope;
    /**
     * Callback for when the submit button is pressed. It provides the mfaId and mfaCode for use.
     */
    onSubmit: (mfa: { mfaId: string; mfaCode: string }) => void | Promise<void>;
    /**
     * Callback for when the onSubmit throws an error unrelated to invalid mfa code.
     * 400 or 401 errors related to mfa will automatically be handled.
     */
    onError?: (e: unknown) => void | Promise<void>;
    /**
     * Callback for when onSubmit throws an error due to invalid mfa code.
     */
    onInvalidCode?: (e: unknown) => void | Promise<void>;
    /**
     * Callback for when the cancel button is pressed.
     * If mfa fails to start, this will also be called.
     */
    onCancel?: () => void;
    /**
     * Whether resending the MFA code is allowed. Defaults to true.
     */
    allowResend?: boolean;
    /**
     * Whether the MFA form should automatically submit when the code is fully entered. Defaults to true.
     */
    autoSubmit?: boolean;
    /**
     * Dialog stays open while submitting, closes after success instead
     */
    keepOpenWhileSubmitting?: boolean;
    /**
     * Tracking page context for analytics.
     */
    pageContext?: AnalyticsContextValue;
    /**
     * Tracking page name for analytics.
     */
    pageName?: string;
  }) => Promise<void>;
  requirePassword: (config: {
    /**
     * Callback for when the submit button is pressed. It provides the mfaId and mfaCode for use.
     */
    onSubmit: (password: string) => void | Promise<void>;
    /**
     * Callback for when the cancel button is pressed.
     * If mfa fails to start, this will also be called.
     */
    onCancel?: () => void;
    /**
     * Tracking page context for analytics.
     */
    pageContext?: AnalyticsContextValue;
    /**
     * Tracking page name for analytics.
     */
    pageName?: string;
  }) => void;
  /**
   * Whether the onSubmit callback is currently executed.
   */
  isSubmitting: boolean;
}

export const StepUpAuthContext = createContext<StepUpAuthContextValue | undefined>(undefined);
