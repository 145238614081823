'use client';

import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { Theme, type ThemeName } from '@cxnpl/ui';
import { ThemeSwitcherContext } from './ThemeSwitcherContext';

export interface ThemeSwitcherProviderProps {
  children: ReactNode;
}

export const ThemeSwitcherProvider = ({ children }: ThemeSwitcherProviderProps) => {
  const [theme, setTheme] = useState<ThemeName>('light'); // TODO Get default theme from system or user settings

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  }, []);

  return (
    <ThemeSwitcherContext.Provider value={{ theme, setTheme, toggleTheme }}>
      <Theme name={theme}>{children}</Theme>
    </ThemeSwitcherContext.Provider>
  );
};
