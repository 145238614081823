'use client';

import type { ReactNode } from 'react';
import { I18nextProvider, type I18nextProviderProps } from 'react-i18next';
import defaultI18n, { translationsNs } from '../i18n/i18n.config';

export interface TranslationsProviderProps extends Partial<I18nextProviderProps> {
  children: ReactNode;
}

export function TranslationsProvider({ defaultNS, i18n, children, ...props }: TranslationsProviderProps) {
  return (
    <I18nextProvider defaultNS={defaultNS ?? translationsNs} i18n={i18n ?? defaultI18n} {...props}>
      {children}
    </I18nextProvider>
  );
}
