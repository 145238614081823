import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { TokenInfo } from 'app/types/token';

const tokenInfoInitialState: TokenInfo = {
  userId: '',
  entityAccountId: '',
  permissionIds: [],
  expiry: null,
  permissionsStripped: false,
};

const tokenInfoSlice = createSlice({
  name: 'tokenInfo',
  initialState: { ...tokenInfoInitialState },
  reducers: {
    setTokenInfo: (_, action: PayloadAction<TokenInfo>) => {
      return action.payload;
    },
    resetTokenInfo: () => {
      return { ...tokenInfoInitialState };
    },
  },
});

export const { setTokenInfo, resetTokenInfo } = tokenInfoSlice.actions;

export default tokenInfoSlice.reducer;
