'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';
import { Platform } from 'react-native';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true, // refetch on window change
      retry: 1, // retry once
    },
  },
});

export function ReactQueryProvider({ children, devtools = true }: { children: ReactNode; devtools?: boolean }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {devtools && Platform.OS === 'web' ? <ReactQueryDevtools buttonPosition="bottom-left" /> : null}
    </QueryClientProvider>
  );
}
