export function generateHash(length = 6): string {
  // Define the characters to use, excluding 'O' and '0' to minimize confusion
  const chars = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
  const nums = new Uint8Array(length);

  crypto.getRandomValues(nums);

  let hash = '';
  for (const num of nums) {
    hash += chars.charAt(num % chars.length);
  }

  return hash;
}

export function validateHash(hash: string, length = 6): boolean {
  // Define the valid characters, excluding 'O' and '0'
  const validChars = /^[A-NP-Z1-9]+$/;

  if (hash.length !== length || !validChars.test(hash)) {
    return false;
  }

  return true;
}
