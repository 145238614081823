import { useCallback, useEffect, useRef, useState } from 'react';

export const useCountdown = () => {
  const [countdown, setCountdown] = useState<number>(0);
  const interval = useRef<NodeJS.Timeout | null>(null);

  // Clear interval once countdown has reached 0
  useEffect(() => {
    if (countdown <= 0 && interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [countdown]);

  // Clear interval on unmount
  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, []);

  const startCountdown = useCallback((time: number) => {
    setCountdown(time);
    // Clear existing interval
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    interval.current = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown - 1 < 0 ? 0 : prevCountdown - 1));
    }, 1000);
  }, []);

  return { startCountdown, countdown };
};

export default useCountdown;
