/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import type { LogErrorBody } from '../api.schemas';
import { customAxios } from '../axios';

/**
 * Send error log to the backend to help track user errors
 * @summary Webapp error logs
 */
export const logError = (logErrorBody: LogErrorBody) => {
  return customAxios<void>({
    url: `/webapp/errorLog`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: logErrorBody,
  });
};

export const getLogErrorMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof logError>>, { data: LogErrorBody }> = (props) => {
    const { data } = props ?? {};

    return logError(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LogErrorMutationResult = NonNullable<Awaited<ReturnType<typeof logError>>>;
export type LogErrorMutationBody = LogErrorBody;
export type LogErrorMutationError = unknown;

/**
 * @summary Webapp error logs
 */
export const useLogError = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof logError>>, TError, { data: LogErrorBody }, TContext> => {
  const mutationOptions = getLogErrorMutationOptions(options);

  return useMutation(mutationOptions);
};
