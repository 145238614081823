import type { CognitoIdTokenPayload } from 'aws-jwt-verify/jwt-model';
import type { PermissionId } from 'app/types/roles';

interface ExtraTokenClaims {
  // temporary types made worse
  entityAccountId: string;
  userId: string;
  permissionIds: PermissionId[];
  requirementIds: string[];
  roleIds: string[];
  kycStatus: string;
  permissionsStripped: string;
}

export type TokenClaims = CognitoIdTokenPayload & ExtraTokenClaims;
export type RawTokenClaims = TokenClaims & { permissionIds: string };

// This function should hit the API and return the status of the token. Its fine for blocking pages locally,
// but should be uplifted to an endpoint that validates using the secret key
export const unsafeParseJwt = (token: string): TokenClaims | null => {
  try {
    const middle = token.split('.')[1];
    if (!middle) {
      return null;
    }

    const parsed = JSON.parse(atob(middle)) as RawTokenClaims;

    // Auth0 and Cognito access tokens currently have different form of permissionIds
    let permissionIds: PermissionId[];
    if (typeof parsed.permissionIds === 'string') {
      permissionIds = parsed.permissionIds.split(',') as PermissionId[];
    } else {
      permissionIds = parsed.permissionIds;
    }
    const tokenClaims: TokenClaims = { ...parsed, permissionIds };
    return tokenClaims;
  } catch (e) {
    return null;
  }
};
