'use client';

import {
  PORTAL_ROOT_ELEMENT,
  Stack,
  PortalProvider as TamaguiPortalProvider,
  isWeb,
  type PortalProviderProps as TamaguiPortalProviderProps,
} from '@cxnpl/ui';
import type { ReactNode } from 'react';

export interface PortalProviderProps extends Partial<TamaguiPortalProviderProps> {
  children: ReactNode;
}

export function PortalProvider({ children, ...props }: PortalProviderProps) {
  return (
    // Tamagui Portal Provider for Tamagui Dialog https://tamagui.dev/ui/dialog/1.0.0#portalprovider
    <TamaguiPortalProvider {...props}>
      {/* The id below is used to by the Portal component */}
      <Stack id={PORTAL_ROOT_ELEMENT} flexGrow={1}>
        <Stack
          flexGrow={1}
          // Separate stacking context so that portals, which are rendered adjacent to this, are always placed visually above children, regardless of children's z-index
          style={
            isWeb
              ? {
                  isolation: 'isolate',
                }
              : {
                  zIndex: 0,
                }
          }
        >
          {children}
        </Stack>
      </Stack>
    </TamaguiPortalProvider>
  );
}
