import type { AuthenticationResultType, Refresh200 } from '@cxnpl/api/api.schemas';
import { refresh as auth0Refresh } from '@cxnpl/api/auth0-api-collection-placeholder/auth0-api-collection-placeholder';
import { refreshToken as cognitoRefresh } from '@cxnpl/api/users/users';
import { appClient } from 'app/utils/appClient';

/**
 * Refresh user token via configured axios instance.
 * Axios instance has interceptors which will refresh the token if it is expired.
 * If you wish to call this method within a component, consider using the hook
 * `useRefreshToken` instead, defined in `app/api/internal/useRefreshToken.ts`.
 *
 * @param entityId - The entity ID of the business to refresh the token for.
 * @param auth0Enabled - Whether Auth0 is enabled.
 */
export async function refreshToken(
  {
    entityId,
    auth0Enabled,
    refreshToken: token,
  }: { entityId?: string; auth0Enabled: boolean; refreshToken?: string } = { auth0Enabled: false }
): Promise<AuthenticationResultType | Refresh200> {
  if (auth0Enabled) {
    const result = await auth0Refresh({ entityId, appClient });
    return result;
  }
  const result = cognitoRefresh({ entityId, appClient, refreshToken: token }) as AuthenticationResultType;
  return result;
}
