'use client';

// eslint-disable-next-line no-restricted-imports -- JULIAN TODO: come back and suss
import dynamic from 'next/dynamic';
import type { ReactNode } from 'react';

const isTestEnv = process.env.DEPLOYMENT_ENV_PROFILE === 'test';

// Don't try to load the FirebaseAnalyticsProvider on the server-side since we are using Firebase that requires "window" object
// in the runtime. Firebase wasn't build to work on the server-side really.
// Related issue:
// - https://stackoverflow.com/questions/69799682/firebase-analytics-with-next-js-window-not-defined
// - https://www.reddit.com/r/Firebase/comments/19750t8/window_not_found_error/
const FirebaseAnalyticsProvider = dynamic(() => import('../FirebaseAnalytics/FirebaseAnalyticsProvider'), {
  ssr: false,
});

export function AnalyticsProviderClientSide({ children }: { children: ReactNode }) {
  // Ignore mounting Analytics components in test environment
  if (isTestEnv) {
    return <>{children}</>;
  }

  // In client-side, we want to instrument Next.js to lazy-load the Firebase SDK
  return <FirebaseAnalyticsProvider>{children}</FirebaseAnalyticsProvider>;
}
