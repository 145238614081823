'use client';

import { useContext } from 'react';
import { RouteHistoryContext } from './provider';

export function useRouteHistory() {
  const context = useContext(RouteHistoryContext);
  if (context === undefined) {
    throw new Error('useRouteHistory must be used within a RouteHistoryProvider');
  }
  return context;
}
